<template>
    <b-overlay :show="loading">
        <b-card-actions
          action-collapse
          ref="refreshCard"
          title="Daftar Supplier"
        >
        <modal-supplier :item="currentItem" @loading="toggleLoading" @submit="submit"></modal-supplier>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-button
              v-if="allowCreate()"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click.prevent="add"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ (row.index + 1) }}
                  </strong>
                </template>
                <template #cell(actions)="row">
                  <b-button
                  v-if="allowUpdate()"
                  v-b-tooltip.hover.right="'Ubah'"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                  <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                  v-if="allowDelete()"
                  v-b-tooltip.hover.right="'Hapus'"
                    size="sm"
                    @click="remove(row.item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                  <feather-icon icon="TrashIcon"/>
                  </b-button>
                </template>
              </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-actions>
    </b-overlay>
  </template>
  
  <script>
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,VBTooltip,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay
  } from "bootstrap-vue";
  import ModalSupplier from './components/Modal.vue'
  
  export default {
    components: {
        ModalSupplier,
      BCardActions,VBTooltip,ToastificationContent,
      BModal,
      BTable,
      BOverlay,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    data() {
      return {
        loading: false,
        showModalTambah: false,
        id: null,
        nama_jabatan: null,
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        currentItem: {},
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: "",
          content: "",
        },
        fields: [
          {
            key: "no",
            label: "No",
          },
          { key: "nama", label: "Nama", sortable: true },
          { key: "instansi", label: "Instansi", sortable: true },
          { key: "email", label: "Email", sortable: true },
          { key: "nohp", label: "No. Handphone", sortable: true },
          { key: "alamat", label: "Alamat", sortable: true },
          { key: "actions", label: "Aksi" }
        ],
        items: [],
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    created() {
      // Set the initial number of items
      this.getData()
    },
    methods: {
        toggleLoading(val) {
            this.loading = val
        },
      add() {
        this.resetForm()
        this.$bvModal.show('modal-supplier')
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data Supplier ${item.nama} ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            item.fungsi = 1 // soft delete
            const payload = {
                fungsi: 1,
                id: item.id
            }
            this.$store.dispatch('supplier/save', [payload])
            .then(() => {
              this.displaySuccess({
                message: 'Berhasil'
              })
              this.resetForm()
              this.getData()
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
          }
        })
      },
      resetForm() {
        this.id = null
      },
      async submit(payload) {
        if(this.id) {
            payload.id = this.id
        }
        if(this.isOwner) {
          payload.jenis = 1
        }
        else {
          payload.jenis = 2
        }
        try {
            this.loading = true
            await this.$store.dispatch('supplier/save', [payload])
            this.displaySuccess({
                message: 'Berhasil'
            })
            this.getData()
            this.$bvModal.hide('modal-supplier')

            this.loading = false
        }
        catch(e) {
            this.loading = false
            this.displayError(e)
            return false
        }
      },
      edit(item) {
        this.id = item.id
        this.currentItem = item
        this.$bvModal.show('modal-supplier')
      },
      async getData() {
        try {
          const params = this.isGM ? {jenis: 2} : {}
            this.items = await this.$store.dispatch('supplier/getData', params)
            this.totalRows = this.items.length
        }
        catch(e) {
            this.displayError(e)
            return false
        }
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
  </script>
  